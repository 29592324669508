import { createRouter, createWebHistory } from "vue-router"
/**
 * isTabbar 是否显示底部导航栏 true显示 false不显示
 * keepAlive true 页面 缓存 false  不缓存
 */
const routes = [{
        path: '/',
        name: 'home',
        meta: { title: '有灵龙 惠生活', isTabbar: true, keepAlive: true },
        component: () =>
            import ( /* webpackChunkName: "home" */ "@/views/home/index.vue")
    },
    {
        path: '/daPerson',
        name: 'daPerson',
        meta: { title: '达人专区', isTabbar: true, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "daPerson" */ "@/views/daPerson/index.vue")
    },
    {
        path: '/newDaPerson',
        name: 'newDaPerson',
        meta: { title: '达人专区', isTabbar: true, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "newDaPerson" */ "@/views/newDaPerson/index.vue")
    },
    {
        path: '/register',
        name: 'register',
        meta: { title: '注册', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "register" */ "@/views/loginRegister/register.vue")
    },
    {
        path: '/setPassword',
        name: 'setPassword',
        meta: { title: '设置密码', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "setPassword" */ "@/views/loginRegister/setPassword.vue")
    }, {
        path: '/login',
        name: 'login',
        meta: { title: '登录', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "login" */ "@/views/loginRegister/login.vue")
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        meta: { title: '找回密码', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "forgetPassword" */ "@/views/loginRegister/forgetPassword.vue")
    },{
        path: '/setRealName',
        name: 'setRealName',
        meta: { title: '设置真实姓名', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "forgetPassword" */ "@/views/loginRegister/setRealName.vue")
    }, {
        path: '/resetPassword',
        name: 'resetPassword',
        meta: { title: '重置密码', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "resetPassword" */ "@/views/loginRegister/resetPassword.vue")
    },
    {
        path: '/search',
        name: 'search',
        meta: { title: '搜索', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "searchHome" */ "@/views/search/searchHome/index.vue")
    },
    {
        path: '/searchResult',
        name: 'searchResult',
        meta: { title: '搜索结果', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "searchResult" */ "@/views/search/searchResult/index.vue")
    }, {
        path: '/categary',
        name: 'categary',
        meta: {
            title: '分类筛选',
            isTabbar: false,
            keepAlive: false,
            isBack: false
        },
        component: () =>
            import ( /* webpackChunkName: "categary" */ "@/views/categary/index.vue")
    },
    {
        path: '/user',
        name: 'user',
        meta: { title: '我的', isTabbar: true, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "user" */ "@/views/user/home/index.vue")
    },
    {
        path: '/goodsDetail',
        name: 'goodsDetail',
        meta: { title: '商品详情', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "goodsDetail" */ "@/views/goodsDetail/index.vue")
    },
    {
        path: '/share',
        name: 'share',
        meta: { title: '创建分享', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "share" */ "@/views/share/index.vue")
    },
    {
        path: '/shareDaPerson',
        name: 'shareDaPerson',
        meta: { title: '创建分享', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "shareDaPerson" */ "@/views/shareDaPerson/index.vue")
    },
    {
        path: '/brandIntroduce',
        name: 'brandIntroduce',
        meta: { title: '品牌介绍', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "brandIntroduce" */ "@/views/brandIntroduce/index.vue")
    },
    {
        path: '/evaluation',
        name: 'evaluation',
        meta: { title: '全部交易评价', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "evaluation" */ "@/views/evaluation/index.vue")
    }, {
        path: '/inviteFriend',
        name: 'inviteFriend',
        meta: { title: '邀请好友', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "inviteFriend" */ "@/views/user/inviteFriend/index.vue")
    },
    {
        path: '/myFans',
        name: 'myFans',
        meta: { title: '我的粉丝', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "myFans" */ "@/views/user/myFans/index.vue")
    },
    {
        path: '/teamReport',
        name: 'teamReport',
        meta: { title: '团队报表', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "teamReport" */ "@/views/user/teamReport/index.vue")
    },
    {
        path: '/profitRecord',
        name: 'profitRecord',
        meta: { title: '收益记录', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "profitRecord" */ "@/views/user/profitRecord/index.vue")
    }, {
        path: '/orderTrue',
        name: 'orderTrue',
        meta: { title: '确认下单', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "orderTrue" */ "@/views/order/orderTrue/index.vue")
    }, 
    {
        path: '/membersOrderTrue',
        name: 'membersOrderTrue',
        meta: { title: '确认订单', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "membersOrderTrue" */ "@/views/order/membersOrderTrue/index.vue")
    },{
        path: '/paySuccess',
        name: 'paySuccess',
        meta: { title: '支付成功', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "paySuccess" */ "@/views/order/paySuccess/index.vue")
    }, {
        path: '/membersPaySuccess',
        name: 'membersPaySuccess',
        meta: { title: '支付成功', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "membersPaySuccess" */ "@/views/order/membersPaySuccess/index.vue")
    }, {
        path: '/membersPayError',
        name: 'membersPayError',
        meta: { title: '支付失败', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "membersPayError" */ "@/views/order/membersPayError/index.vue")
    }, {
        path: '/orderList',
        name: 'orderList',
        meta: { title: '我的订单', isTabbar: true, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "orderList" */ "@/views/order/orderList/index.vue")
    }, {
        path: '/orderDetail',
        name: 'orderDetail',
        meta: { title: '订单详情', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "orderDetail" */ "@/views/order/orderDetail/index.vue")
    }, {
        path: '/dhOrderDetail',
        name: 'dhOrderDetail',
        meta: { title: '订单详情', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "dhOrderDetail" */ "@/views/order/dhOrderDetail/index.vue")
    },
    {
        path: '/balanceCashOut',
        name: 'balanceCashOut',
        meta: { title: '余额提现', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "balanceCashOut" */ "@/views/user/balanceCashOut/index.vue")
    },
    {
        path: '/cashRecord',
        name: 'cashRecord',
        meta: { title: '收益明细', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "cashRecord" */ "@/views/user/cashRecord/index.vue")
    },
    {
        path: '/platformRules',
        name: 'platformRules',
        meta: { title: '平台规则', keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "platformRules" */ "@/views/user/platformRules/index.vue")
    },
    {
        path: '/platformExplain',
        name: 'platformExplain',
        meta: { title: '平台使用规范说明', keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "platformExplain" */ "@/views/user/platformExplain/index.vue")
    },
    {
        path: '/myCollect',
        name: 'myCollect',
        meta: { title: '我的收藏', keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "myCollect" */ "@/views/user/myCollect/index.vue")
    },
    {
        path: '/addAddress',
        name: 'addAddress',
        meta: { title: '新增地址', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "addAddress" */ "@/views/address/addAddress/index.vue")
    }, {
        path: '/addressList',
        name: 'addressList',
        meta: { title: '收货地址', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "addressList" */ "@/views/address/addressList/index.vue")
    }, {
        path: '/afterService',
        name: 'afterService',
        meta: { title: '申请售后', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "afterService" */ "@/views/order/afterService/index.vue")
    }, {
        path: '/applyAfter',
        name: 'applyAfter',
        meta: { title: '申请售后', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "applyAfter" */ "@/views/order/applyAfter/index.vue")
    }, {
        path: '/afterSuccess',
        name: 'afterSuccess',
        meta: { title: '申请售后', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "afterSuccess" */ "@/views/order/afterSuccess/index.vue")
    }, {
        path: '/afterDetail',
        name: 'afterDetail',
        meta: { title: '售后详情', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "afterDetail" */ "@/views/order/afterSuccess/index.vue")
    }, {
        path: '/logisticsInfo',
        name: 'logisticsInfo',
        meta: { title: '物流信息', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "logisticsInfo" */ "@/views/order/logisticsInfo/index.vue")
    }, {
        path: '/myNotice',
        name: 'myNotice',
        meta: { title: '我的公告', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "myNotice" */ "@/views/user/myNotice/index.vue")
    }, {
        path: '/noticeContent',
        name: 'noticeContent',
        meta: { title: '公告内容', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "noticeContent" */ "@/views/user/noticeContent/index.vue")
    }, {
        path: '/couponList',
        name: 'couponList',
        meta: { title: '优惠券', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "couponList" */ "@/views/user/couponList/index.vue")
    }, {
        path: '/couponHistory',
        name: 'couponHistory',
        meta: { title: '优惠券', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "couponHistory" */ "@/views/user/couponHistory/index.vue")
    }, {
        path: '/setCashPassword',
        name: 'setCashPassword',
        meta: { title: '设置提现密码', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "setCashPassword" */ "@/views/user/setCashPassword/index.vue")
    }, {
        path: '/againCashPassword',
        name: 'againCashPassword',
        meta: { title: '设置提现密码', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "againCashPassword" */ "@/views/user/againCashPassword/index.vue")
    }, {
        path: '/identityProof',
        name: 'identityProof',
        meta: { title: '验证身份', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "identityProof" */ "@/views/user/identityProof/index.vue")
    }, {
        path: '/inputProof',
        name: 'inputProof',
        meta: { title: '验证身份', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "inputProof" */ "@/views/user/setCashPassword/index.vue")
    }, {
        path: '/againProof',
        name: 'againProof',
        meta: { title: '验证身份', isTabbar: false, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "againProof" */ "@/views/user/againCashPassword/index.vue")
    }, {
        path: '/businessOverview',
        name: 'businessOverview',
        meta: { title: '业务概况', isTabbar: true, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "businessOverview" */ "@/views/businessOverview/index.vue")
    }, {
        path: '/secondUser',
        name: 'secondUser',
        meta: { title: '个人中心', isTabbar: true, keepAlive: false },
        component: () =>
            import ( /* webpackChunkName: "secondUser" */ "@/views/user/secondHome/index.vue")
    }, {
        path: '/memberInterest',
        name: 'memberInterest',
        meta: { title: '灵龙达人权益介绍', isTabbar: false },
        component: () =>
            import ( /* webpackChunkName: "memberInterest" */ "@/views/memberInterest/index.vue")
    }, {
        path: '/taxCommit',
        name: 'taxCommit',
        meta: { title: '税收承诺书', isTabbar: false },
        component: () =>
            import ( /* webpackChunkName: "taxCommit" */ "@/views/user/balanceCashOut/taxCommit/index.vue")
    }, {
        path: '/nameCertification',
        name: 'nameCertification',
        meta: { title: '实名认证', isTabbar: false },
        component: () =>
            import ( /* webpackChunkName: "nameCertification" */ "@/views/user/balanceCashOut/nameCertification/index.vue")
    }, {
        path: '/storeAuth',
        name: 'storeAuth',
        meta: { title: '门店认证', isTabbar: false },
        component: () =>
            import ( /* webpackChunkName: "storeAuth" */ "@/views/store/storeAuth/index.vue")
    }, {
        path: '/secondsKill',
        name: 'secondsKill',
        meta: { title: '限时抢购', isTabbar: false },
        component: () =>
            import ( /* webpackChunkName: "secondsKill" */ "@/views/secondsKill/index.vue")
    }, { // 限时抢购 商品详情
        path: '/secondsKillGoodsDetail',
        name: 'secondsKillGoodsDetail',
        meta: { title: '商品详情', isTabbar: false },
        component: () =>
            import ( /* webpackChunkName: "secondsKillGoodsDetail" */ "@/views/skillGoodsDetail/index.vue")
    }, { // 协议
        path: '/buyAgreement',
        name: 'buyAgreement',
        meta: { title: '课程购买协议', isTabbar: false },
        component: () =>
            import ( /* webpackChunkName: "buyAgreement" */ "@/views/buyAgreement/index.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export function setupRouter(app) {
    app.use(router);
    // 创建路由守卫
}
export default router;