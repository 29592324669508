import axios from "./request"

// 首页轮播图
export const getBanner = (data) => {
    return axios({
        url: "/api/member/getAdvertList",
        method: "get",
        data
    })
}

// 首页公告
export const getNotice = (data) => {
    return axios({
        url: "/merchant/WxHeadLinesList/getHeadLines",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 公告详情
export const noticeDetail = (data) => {
    return axios({
        url: "/merchant/WxHeadLinesList/getText",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 首页商品分类
export const getCategory = (data) => {
    return axios({
        url: "/api/member/findCategory",
        method: "get",
        data
    })
}

// 首页商品
export const getHomeGoods = (data) => {
    return axios({
        url: "/api/weixin/mall/queryGoodList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 分类筛选
export const getAllCategory = (data) => {
    return axios({
        url: '/api/member/findShopCategory',
        method: 'get',
        data
    })
}

// 根据品牌获取商品
export const getCategoryGoods = (data) => {
    return axios({
        url: '/api/weixin/mall/getBrandGoods',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 获取搜索推荐
export const searchRecommend = (data) => {
    return axios({
        url: '/api/weixin/mall/searchRecommend',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 是否弹出优惠券
export const getNewMemberCoupon = (data) => {
    return axios({
        url: '/api/member/getNewMemberCoupon',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            requestReturn: true
        }
    })
}

// 获取用户角色和 是否 是达人
export const getUserStatus = (data) => {
    return axios({
        url: '/api/member/getMemberIdentity',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            requestReturn: true
        }
    })
}

// 获取微信配置信息
export const wxConfig = (data) => {
    return axios({
        url: '/api/oAuth2/JsSdk',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            requestReturn: true
        }
    })
}