import axios from "./request"

// 获取个人信息
export const getweixininfo = (data) => {
    return axios({
        url: "/api/member/getweixininfo",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 获取个人中心banner
export const getAdvertList = (data) => {
    return axios({
        url: "/api/member/getAdvertList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 申请提现
export const wxWithdrawalPay = (data) => {
    return axios({
        url: "/api/pay/wxWithdrawal",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 设置提现密码
export const setWithdrawPassword = (data) => {
    return axios({
        url: "/api/wallet/setWithdrawPassword",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 重置提现密码
export const resetWithdrawPassword = (data) => {
    return axios({
        url: "/api/wallet/resetWithdrawPassword",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 修改提现密码
export const updateWithdrawPassword = (data) => {
    return axios({
        url: "/api/wallet/updateWithdrawPassword",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 提现列表
export const getCashList = (data) => {
    return axios({
        url: "/api/member/getTradeWithdraws",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 提现详情
export const getTransOrderDetail = (data) => {
    return axios({
        url: "/api/member/getTransOrderDetail",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 保存支付宝账号
export const bindZfbSave = (data) => {
    return axios({
        url: "/api/pay/bindZfb",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 获取支付宝账号
export const bindZfbGet = (data) => {
    return axios({
        url: "/api/pay/getZfb",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 获取会员可提现金额
export const getWalletInfo = (data) => {
    return axios({
        url: "/api/member/getWalletInfo",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 检查该用户是否已设置提现密码
export const checkWithdrawPassword = (data) => {
    return axios({
        url: "/api/wallet/checkWithdrawPassword",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            requestReturn: true
        }
    })
}
// 专属粉丝列表+搜索
export const myExclusiveFansList = (data) => {
    return axios({
        url: "/api/member/myExclusiveFansList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 根据会员编号查询会员信息
export const getUserInfo = (data) => {
    return axios({
        url: "/api/member/getmemberinfo",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 潜在粉丝列表
export const myPotentialFansList = (data) => {
    return axios({
        url: "/api/member/myPotentialFansList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 公告详情
export const WxHeadLinesDetail = (data) => {
    return axios({
        url: "/merchant/WxHeadLinesList/getText",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 获取粉丝统计数据
export const myFansInfo = (data) => {
    return axios({
        url: "/api/member/myFans",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 收益记录
export const getRevenueRecord = (data) => {
    return axios({
        url: "/api/member/getRevenueRecord",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 获取用户是否已确认和实名认证
export const checkMemberAuth = (data) => {
    return axios({
        url: "/api/member/checkMemberAuth",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 确认税务
export const confirmTax = (data) => {
    return axios({
        url: "/api/member/confirmTax",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 实名认证
export const realNameAuth = (data) => {
    return axios({
        url: "/api/member/realNameAuth",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 地推的业务报表
export const myBusinessReport = (data) => {
    return axios({
        url: "/api/member/myBusinessReport",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 地推业绩列表
export const myExFansList = (data) => {
    return axios({
        url: "/api/member/myExFansList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 推荐佣金列表
export const myRecommendAmountList = (data) => {
    return axios({
        url: "/api/member/myRecommendAmountList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 返佣佣金列表
export const myReturnAmountList = (data) => {
    return axios({
        url: "/api/member/myReturnAmountList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 地推个人中心
export const myProfile = (data) => {
    return axios({
        url: "/api/member/myProfile",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 团队报表
export const groupReport = (data) => {
    return axios({
        url: "/api/member/groupReport",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 获取门店认证情况
export const getShopAuth = (data) => {
    return axios({
        url: "/api/member/getShopAuth",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            requestReturn: true
        }
    })
}
// 门店认证
export const shopAuth = (data) => {
    return axios({
        url: "/api/member/shopAuth",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 收藏列表
export const myCollectList = (data) => {
    return axios({
        url: "/api/member/myCollectList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

/**
 * api/member/updateToVip
 * memberId=会员编号
 * code=邀请码
 */
 export const updateToVip = (data) => {
    return axios({
        url: "/api/member/updateToVip",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

/**
 * 收益奖励列表
 * api/member/myProfitList
 * memberId
 * pageNo
 * pageSize
 */
 export const myProfitList = (data) => {
    return axios({
        url: "/api/member/myProfitList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}