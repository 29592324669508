<template>
  <div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component"  v-if="$route.meta.keepAlive" :key="$route.path"/>
      </keep-alive>
      <component :is="Component"  v-if="!$route.meta.keepAlive" :key="$route.path"/>
    </router-view>
    <template v-if="isTabbar">
      <tabbar v-if="isFlag"></tabbar>
      <tj-tabbar v-if="!isFlag"></tj-tabbar>
    </template>
    <home-dialog v-if="isHome"></home-dialog>
  </div>
</template>

<script>
import tabbar from "@/components/tabbar.vue";
import tjTabbar from "@/components/tjTabbar.vue";
import homeDialog from '@/components/home.vue';
import { getUserInfo } from '@/utils/user.js';
import { getUrlParam } from '@/utils/common.js';
import { weichatTools } from '@/utils/weichatShare.js';
export default {
  name: 'App',
  components: {
    tabbar,
    tjTabbar,
    homeDialog
  },
  data() {
    return {
      isTabbar: false,
      isShow: true,
      memberId: '',
      isFlag: true,//  false 推广 true 普通会员或者达人
      isHome: false, // 是否显示回到首页按钮
    }
  },
  watch: {
    $route(to, from) {
      console.log('to', to, from)
      if (getUrlParam('shareMemberId')) {
        this.isTabbar = false;
      } else {
        this.isTabbar = to.meta.isTabbar;
      }
      
      // document.title = to.meta.title;
      // 判断是否是推广人员
      this.isFlag = this.$cookies.get('userType') && this.$cookies.get('userType') == '9' ? false : true;

      // 是否显示回到首页按钮
      let arrUrl = ['/login', '/goodsDetail', '/orderDetail', '/couponList', '/myFans', '/noticeContent', '/memberInterest', '/cashRecord', '/categary', '/secondsKill', '/secondsKillGoodsDetail']
      if (arrUrl.includes(to.path)) {
        this.isHome = true;
      } else {
        this.isHome = false;
      }

      // 分享全局配置
      let shareUrl = ['/share', '/goodsDetail'];
      if (!shareUrl.includes(to.path)) {
        let memberId = getUrlParam('memberId') ? getUrlParam('memberId') : this.$cookies.get('memberId');
        const imgUrl = 'http://yll.cn-sh2.ufileos.com/wx/logo2.png';
        // let shareUrl = 'https://wx.linglong-goods.com/yllService/api/menu/personal?name=' + window.location.pathname.split('/')[1] + '&shareMemberId=' + memberId;
        let shareUrl = 'http://tm-wx.linglong-goods.com/FxService/api/menu/personal?name=newDaPerson&shareMemberId=' + memberId;
        weichatTools.share.shareFunc('有灵龙 惠生活', '快速上手抖音达人玩法，赚海量推广佣金', shareUrl, imgUrl);
      }
    },
  },
  beforeCreate() {
    weichatTools.wxConfigFunc();
  },
  created() {
    this.$cookies.config('1d');
    this.memberId = getUrlParam('memberId') ? getUrlParam('memberId') : this.$cookies.get('memberId');
    this.$cookies.set('memberId', this.memberId);
    // 分享id
    if (getUrlParam('shareMemberId')) {
      sessionStorage.setItem('shareMemberId', getUrlParam('shareMemberId'));
      // this.$cookies.set('shareMemberId', getUrlParam('shareMemberId'));
    }

    // 全局分享
    this.$nextTick(() => {
      let shareUrl = 'http://tm-wx.linglong-goods.com/FxService/api/menu/personal?name=newDaPerson&shareMemberId=' + this.memberId;
      const imgUrl = 'http://yll.cn-sh2.ufileos.com/wx/logo2.png';
      weichatTools.share.shareFunc('有灵龙 惠生活', '快速上手抖音达人玩法，赚海量推广佣金', shareUrl, imgUrl);
    })
    // if (!this.$cookies.get('isFirst')) {
    //   this.$cookies.set('isFirst', 0, '10y');
    // }
    // 判断是否是推广人员
    this.isFlag = this.$cookies.get('userType') && this.$cookies.get('userType') == '9' ? false : true;
    this.getUserInfoFunc();
  },
  methods: {
    // 获取用户信息
    getUserInfoFunc() {
      getUserInfo({memberId: this.memberId}).then(res => {
        const resData = res && res.data ? res.data : {}
        console.log('resData111', resData)
        sessionStorage.setItem('userInfo', JSON.stringify(resData))
        // 会员类型 0=游客,1=普通会员,2=VIP会员,3=达人,4=超级达人5=源头达人
        // this.$cookies.set('isLogin', 'true', '1d');
        this.$cookies.set('userId', resData.user_id, '1d');
        this.$cookies.set('userType', resData.user_type, '1d');
        // this.$cookies.set('isDaPerson', resData.user_type);
      })
    }
  }
}
</script>

<style>
@import url("./assets/common.css");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
