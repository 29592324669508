import { wxConfig } from "@/utils/home";
import wx from "weixin-js-sdk";
// const check_is_weichat = () => {
//     let is_weichat = false;
//     if (/MicroMessenger/i.test(navigator.userAgent)) {
//         is_weichat = true;
//     }
//     return is_weichat;
// }
const weichatTools = {
    wxConfigFunc() {
        let data = {
            url: window.location.href.split('#')[0]
        }
        wxConfig(data).then(res => {
            let needApi = ['onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareQZone', 'updateAppMessageShareData', 'updateTimelineShareData', 'hideMenuItems'];
            if (res.code == 0) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.data.appId, // 必填，公众号的唯一标识
                    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.signature, // 必填，签名
                    jsApiList: needApi // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
            }
        })
    },
    share: { //调起微信分享功能
        shareFunc: function(title, message, link, imgUrl) {
            // if (check_is_weichat()) {
            //自定义“分享给朋友”及“分享到QQ”按钮的分享内容 updateAppMessageShareData
            //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容 updateTimelineShareData
            wx.ready(function() {
                wx.updateAppMessageShareData({
                    title: title, // 分享标题
                    desc: message, // 分享描述
                    link: link, // 分享链接
                    imgUrl: imgUrl, // 分享图标
                    type: '', // 分享类型,music、video或link，不填默认为link
                    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                    success: function() {
                        // 设置成功
                        console.log('成功1111', '')
                    },
                    cancel: function() {
                        // 用户取消分享后执行的回调函数
                    }
                });

                // wx.onMenuShareAppMessage({
                //     title: title, // 分享标题
                //     desc: message, // 分享描述
                //     link: link, // 分享链接
                //     imgUrl: imgUrl, // 分享图标
                //     type: '', // 分享类型,music、video或link，不填默认为link
                //     dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                //     success: function() {
                //         // 设置成功
                //         console.log('成功1111', '')
                //     },
                //     cancel: function() {
                //         // 用户取消分享后执行的回调函数
                //     }
                // });

                // wx.onMenuShareQQ({
                //     title: title, // 分享标题
                //     desc: message, // 分享描述
                //     link: link, // 分享链接
                //     imgUrl: imgUrl, // 分享图标
                //     type: '', // 分享类型,music、video或link，不填默认为link
                //     dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                //     success: function() {
                //         // 设置成功
                //         console.log('成功1111', '')
                //     },
                //     cancel: function() {
                //         // 用户取消分享后执行的回调函数
                //     }
                // });

                // wx.onMenuShareQZone({
                //     title: title, // 分享标题
                //     desc: message, // 分享描述
                //     link: link, // 分享链接
                //     imgUrl: imgUrl, // 分享图标
                //     type: '', // 分享类型,music、video或link，不填默认为link
                //     dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                //     success: function() {
                //         // 设置成功
                //         console.log('成功1111', '')
                //     },
                //     cancel: function() {
                //         // 用户取消分享后执行的回调函数
                //     }
                // });

                wx.updateTimelineShareData({
                    title: title, // 分享标题
                    link: link, // 分享链接
                    imgUrl: imgUrl, // 分享图标
                    success: function() {
                        // 设置成功
                        console.log('成功', '')
                    },
                    cancel: function() {
                        // 用户取消分享后执行的回调函数
                    }
                });

                // wx.onMenuShareTimeline({
                //     title: title, // 分享标题
                //     link: link, // 分享链接
                //     imgUrl: imgUrl, // 分享图标
                //     success: function() {
                //         // 设置成功
                //         console.log('成功', '')
                //     },
                //     cancel: function() {
                //         // 用户取消分享后执行的回调函数
                //     }
                // })
            });
            // }
        },
    }
    //可扩展更多的js-sdk 微信api
}

export {
    weichatTools
}