import { createApp } from 'vue'
import App from './App.vue'
import { vant } from './utils/vant'
import { setupRouter } from './router/index'
import 'lib-flexible/flexible.js'

// 引入vconsole
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()

// 引入cookies
import VueCookies from 'vue-cookies'
const app = createApp(App)
vant(app)

// 挂载vConsole
// app.use(vConsole)

// 挂载全局方法
app.config.globalProperties.$cookies = VueCookies

// 挂载路由
setupRouter(app)

app.mount('#app')