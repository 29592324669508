// http/index.js
import axios from 'axios'
import { createApp } from 'vue';
import { Toast } from 'vant';
import qs from 'qs'
// 引入cookies
// import VueCookies from 'vue-cookies'

// import router from '@/router/index'

const app = createApp();
app.use(Toast);
//创建axios的一个实例
var instance = axios.create({
    // baseURL: 'http://113.31.119.67:8081', //接口统一域名 https://wx.linglong-goods.com
    // baseURL: 'https://wx.linglong-goods.com',
    timeout: 10000, //设置超时
    headers: {
        'Content-Type': 'application/json;charset=UTF-8;',
    }
})
let loading;
//正在请求的数量
let requestCount = 0

//显示loading
const showLoading = () => {
    if (requestCount === 0 && !loading) {
        loading = Toast.loading({
            message: '加载中...',
            forbidClick: true,
        });
    }
    requestCount++;
}

//隐藏loading
const hideLoading = () => {
        requestCount--
        if (requestCount == 0) {
            Toast.clear();
        }
    }
    // 请求返回参数路径
let requestObj = {};
//请求拦截器
instance.interceptors.request.use((config) => {
        showLoading()
        if (config.requestReturn) {
            requestObj[config.url] = true
        }
        // 判断是否登录
        // let arr = ['/login', '/register', '/forgetPassword', '/setPassword', '/resetPassword']
        // if (!arr.includes(window.location.pathname) && !VueCookies.get('isLogin')) {
        //     hideLoading()
        //     router.push({
        //         path: '/login'
        //     })
        // }

        //若请求方式为post，则将data参数转为JSON字符串
        if (config.method === 'post') {
            config.data = qs.stringify(config.data);
        }
        return config;
    }, (error) =>
    // 对请求错误做些什么
    Promise.reject(error));

//响应拦截器
instance.interceptors.response.use((response) => {
    // requestObj[response.config.url]
    if (response.data.code === 0 || response.data.code === 1 || response.data.errorCode == '0000' || response.data.state == '0000') {
        setTimeout(() => {
            hideLoading()
        }, 500)
            //响应成功
        return response.data;
    } else {
        Toast(response.data.message || response.data.errorMsg)
    }
}, (error) => {
    //响应错误
    if (error.response && error.response.status) {
        const status = error.response.status
        let message = ''
        switch (status) {
            case 400:
                message = '请求错误';
                break;
            case 401:
                message = '请求错误';
                break;
            case 404:
                message = '请求地址出错';
                break;
            case 408:
                message = '请求超时';
                break;
            case 500:
                message = '服务器内部错误!';
                break;
            case 501:
                message = '服务未实现!';
                break;
            case 502:
                message = '网关错误!';
                break;
            case 503:
                message = '服务不可用!';
                break;
            case 504:
                message = '网关超时!';
                break;
            case 505:
                message = 'HTTP版本不受支持';
                break;
            default:
                message = '请求失败'
        }
        Toast.fail(message);
        return Promise.reject(error);
    }
    return Promise.reject(error);
});


export default instance;