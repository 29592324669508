import {
    Button,
    Dialog,
    Popup,
    Tabbar,
    TabbarItem,
    Swipe,
    SwipeItem,
    Popover,
    Badge,
    ActionBar,
    ActionBarIcon,
    ActionBarButton,
    RadioGroup,
    Radio,
    Checkbox,
    CheckboxGroup,
    Tab,
    Tabs,
    List,
    AddressEdit,
    Sticky,
    Picker,
    Toast,
    NumberKeyboard,
    DatetimePicker,
    Stepper,
    Empty,
    CouponCell,
    CouponList,
    Uploader,
    Area,
} from 'vant'
// import 'vant/lib/index.css'
export function vant(app) {
    app.use(Button)
        .use(Dialog)
        .use(Popup)
        .use(Tabbar)
        .use(TabbarItem)
        .use(Swipe)
        .use(SwipeItem)
        .use(Popover)
        .use(Badge)
        .use(ActionBar)
        .use(ActionBarIcon)
        .use(ActionBarButton)
        .use(RadioGroup)
        .use(Radio)
        .use(Checkbox)
        .use(CheckboxGroup)
        .use(Tab)
        .use(Tabs)
        .use(List)
        .use(AddressEdit)
        .use(Sticky)
        .use(Picker)
        .use(Toast)
        .use(Stepper)
        .use(Empty)
        .use(NumberKeyboard)
        .use(DatetimePicker)
        .use(CouponCell)
        .use(CouponList)
        .use(Uploader)
        .use(Area)
}