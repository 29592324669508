// http/request.js
import instance from "./http.js"
/**
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} data    请求的参数
 * @param {Object} config  请求的配置
 * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值
 */

const axios = ({
    method,
    url,
    data,
    config
}) => {
    method = method.toLowerCase();
    if (method == 'post') {
        let aUrl = '';
        if (url === '/api/wx/activityInfo/getTodayActivityList') {
            aUrl = 'http://tm-api.linglong-goods.com:5000/seckill' + url;
        } else {
            aUrl = 'https://tm-wx.linglong-goods.com/FxService' + url;
        }
        return instance.post(aUrl, data, {...config })
    } else if (method == 'get') {
        let aUrl = '';
        if (url === '/api/wx/activityInfo/activityInfo') {
            aUrl = 'http://tm-api.linglong-goods.com:5000/seckill' + url;
        } else {
            aUrl = 'https://tm-wx.linglong-goods.com/FxService' + url;
        }
        return instance.get(aUrl, {
            params: data,
            ...config
        })
    } else if (method == 'delete') {
        return instance.delete('https://tm-wx.linglong-goods.com/FxService' + url, {
            params: data,
            ...config
        }, )
    } else if (method == 'put') {
        return instance.put('https://tm-wx.linglong-goods.com/FxService' + url, data, {...config })
    } else {
        console.error('未知的method' + method)
        return false
    }
}
export default axios
