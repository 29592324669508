<template>
  <div class="tabbar">
    <van-tabbar v-model="active" active-color="#E62129" inactive-color="#999999" :safe-area-inset-bottom="true">
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <!-- daPerson -->
      <van-tabbar-item to="/daPerson">
        <span>达人专区</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <!-- 个推 -->
      <van-tabbar-item to="/businessOverview">
        <span>业务概况</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/secondUser">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
  setup() {
    let active = ref(0);
    let aIndex = '';
    switch(window.location.pathname) {
      case '/daPerson':
      case '/newDaPerson':
        aIndex = 1;
        break;
      case '/businessOverview':
        aIndex = 2;
        break;
      case '/secondUser':
        aIndex = 3;
        break;
      default:
        aIndex = 0;
    }
    active = ref(aIndex);
    const icon = {
        active: require('../assets/img/tabbar1/home-sel.png'),
        inactive: require('../assets/img/tabbar1/home.png'),
      };
    const icon1 = {
        active: require('../assets/img/tabbar1/daren-sel.png'),
        inactive: require('../assets/img/tabbar1/daren.png'),
      };
    const icon2 = {
        active: require('../assets/img/tabbar1/order-sel.png'),
        inactive: require('../assets/img/tabbar1/order.png'),
      };
    const icon3 = {
        active: require('../assets/img/tabbar1/person-sel.png'),
        inactive: require('../assets/img/tabbar1/person.png'),
      };
    return {
      icon,
      icon1,
      icon2,
      icon3,
      active,
    };
  },
  data() {
    return {
      
    }
  },
  watch: {
    $route(to, from) {
      console.log('to5678', to.path, from);
      let aIndex = 0;
      let path = to.path;
      switch(path) {
        case '/daPerson':
        case '/newDaPerson':
          aIndex = 1;
          break;
        case '/businessOverview':
          aIndex = 2;
          break;
        case '/secondUser':
          aIndex = 3;
          break;
        default:
          aIndex = 0;
      }
      this.active = aIndex;
    },
  }
}
</script>
<style>
.van-tabbar{
  overflow: hidden !important;
  border-radius: 10px 10px 0px 0px !important;
}
.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after{
  border-width: 0px !important;
}
</style>