<template>
  <div class="home-p" @click="goHomePageFunc">
    <img src="@/assets/img/home_p.png" alt="">
  </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    },
    methods: {
      goHomePageFunc() {
        this.$router.push({
          path: '/'
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.home-p{
  position: fixed;
  right: 5px;
  bottom: 100px;
  z-index: 1;
  width: 72px;
  height: 72px;
  img{
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}
</style>